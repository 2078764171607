import React from "react"

import Tile from "../components/tile"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link"

const IndexPage = ({ transitionStatus }) => {
  return (
    <Layout transitionStatus={transitionStatus}>
      <SEO title="Home" />
      <h1
        className="text-l"
        style={{
          minHeight: "85vh",
          paddingTop: "7rem",
          display: "inline-block",
        }}
        data-scroll
        data-scroll-speed="-1"
      >
        <div className="line">
          <span>pattern</span>
        </div>
        <div className="line">
          <span>brutalIst</span>
        </div>
        <div className="line">
          <span>Illustrated</span>
        </div>
        <div className="line">
          <span>MagazIne</span>
        </div>
        <div className="line" style={{ textAlign: "right" }}>
          <span>2020</span>
        </div>
        <div className="line">
          <span>Issue</span>
        </div>
        <div className="line">
          <span>02</span>
        </div>
      </h1>
      <div className="grid15">
        <Link
          style={{
            gridColumn: "7 / 16",
            gridRow: "1 / 34",
          }}
          to="/satu-mare"
        >
          <Tile id="2" />
        </Link>
        <Link
          style={{
            gridColumn: "1 / 4",
            gridRow: "23 / 34",
          }}
          to="/satu-mare"
        >
          <Tile id="1" />
        </Link>
        <Link
          style={{
            gridColumn: "3 / 9",
            gridRow: "50 / 71",
          }}
          to="/satu-mare"
        >
          <Tile id="3" />
        </Link>
        <Link
          style={{
            gridColumn: "12 / 15",
            gridRow: "60 / 71",
          }}
          to="/satu-mare"
        >
          <Tile id="4" />
        </Link>
        <div style={{ gridColumn: "7 / 9", gridRow: "36 / 45" }}>
          <p>Objects</p>
          <p>
            Cities
            <br />
            <br />
            <br />
            <br />
          </p>
          <p>Collaboration</p>
          <p>Follow</p>
        </div>
        <div style={{ gridColumn: "9 / 16", gridRow: "36 / 45" }}>
          <p>4</p>
          <p>
            Moscow
            <br />
            Vitebsk
            <br />
            Satu Mare
            <br />
            Ghent
            <br />
          </p>
          <p>
            <Link to="mailto: lisovskiyserj@gmail.com" className="rounded">
              lisovskiyserj[at]gmail.com
            </Link>
          </p>
          <p>
            <Link
              to="https://www.instagram.com/patternbrutalist/"
              className="rounded"
            >
              instagram
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
