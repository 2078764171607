import PropTypes from "prop-types"
import React from "react"

const Tile = ({ id }) => {
  const num = id > 0 && id < 5 ? id : 1
  return (
    <div className="tile-container scroll-innert">
      <div className="tile-parallax" data-scroll data-scroll-speed="-2">
        <div className={`tile-pattern pattern-${num}`}></div>
      </div>
    </div>
  )
}

Tile.propTypes = {
  id: PropTypes.string,
}

Tile.defaultProps = {
  id: `1`,
}

export default Tile
